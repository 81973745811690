var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.releaseError)?_c('div',[_c('div',{staticClass:"text-h2 text-center mb-12"},[_vm._v(" "+_vm._s(_vm.releaseError.title)+" ")]),_c('div',{staticClass:"text-center mb-12"},[_vm._v(" "+_vm._s(_vm.releaseError.subtitle.replaceAll('contact us.', ''))+" "),(_vm.releaseError.subtitle.endsWith('contact us.'))?[_c('a',{attrs:{"href":'mailto:' + _vm.CONTACT_EMAIL}},[_vm._v("contact us")]),_vm._v(". ")]:_vm._e()],2),_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-height":"30vh","src":_vm.releaseError.illustration}})],1):(_vm.release)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(!_vm.NonPreviewStatuses.includes(_vm.release.status))?_c('v-app-bar',{staticClass:"mt-16 info-light no-shadow d-flex justify-center",attrs:{"fixed":"","app":""}},[_c('span',[_vm._v(" Note: This release is currently in "),_c('b',[_vm._v("preview mode")]),_vm._v(". Buyers will not be able to access this URL until the release is published and paid for.")])]):_vm._e(),_c('v-window',{class:[
      'mx-auto',
      { 'mt-16': !_vm.NonPreviewStatuses.includes(_vm.release.status) } ],style:(("max-width: " + (_vm.$vuetify.breakpoint.thresholds.sm) + "px")),attrs:{"touchless":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',[(_vm.ReleaseStatus.Draft === _vm.release.status)?_c('BackButton',{attrs:{"to":{ path: ("/releases/" + (_vm.release.id)), query: { confirm: '1' } },"text":"Back to editing"}}):_vm._e(),_c('p',{staticClass:"mt-8 text-h1"},[_vm._v(_vm._s(_vm.name))]),_c('ApplicationDetails',{staticClass:"mt-12 white",attrs:{"release":_vm.release,"sellerLogo":""}}),_c('ReleaseDocuments',{attrs:{"release":_vm.release}}),(!_vm.isOnDemandPurchase)?_c('div',[_c('p',{staticClass:"text-h3 mt-12"},[_vm._v("Your Preferences")]),_c('p',[_vm._v(" Enter your property preferences (numbered from 1 - "+_vm._s(_vm.maximumPreferences)+"). Minimum of 1 and maximum of "+_vm._s(_vm.maximumPreferences)+" preferences must be specified. ")])]):_c('div',[_c('p',{staticClass:"text-h3 mt-12"},[_vm._v("Property Listing")])]),_c('ValidationObserver',[_c('LotPreferenceForm',{staticClass:"mt-6",attrs:{"lots":_vm.lots,"hide-preference":_vm.isOnDemandPurchase,"can-purchase":_vm.isOnDemandPurchase,"lot-statuses":_vm.release.lotStatusMap,"property-type":_vm.release.details.propertyType},on:{"input":_vm.checkPreferences,"purchase":_vm.triggerPurchase},model:{value:(_vm.preferences),callback:function ($$v) {_vm.preferences=$$v},expression:"preferences"}}),_vm._l((_vm.preferenceErrors),function(err,i){return _c('v-card',{key:'prefError-' + i,class:[
            'd-flex',
            'align-center',
            'pa-3',
            { 'mb-4': i < _vm.preferenceErrors.length - 1 } ],attrs:{"flat":"","tile":"","color":"error-light"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"error"}},[_vm._v("$error")]),_c('div',{staticClass:"primary--text text-body-2 inline"},[_vm._v(" "+_vm._s(err)+" ")])],1)}),(!_vm.isOnDemandPurchase)?_c('div',[_c('InfoCard',{staticClass:"my-6",attrs:{"flat":"","padding":4},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$info")])]},proxy:true}],null,false,2150591588)},[_vm._v(" Applications close and must be submitted by "),_c('ApplicationCloseDate',{attrs:{"date":_vm.release.details.applicationsCloseDate}})],1),_c('v-btn',{staticClass:"float-right",attrs:{"disabled":!Object.keys(_vm.preferences).length || _vm.preferenceErrors.length > 0,"block":_vm.$vuetify.breakpoint.xs,"color":"primary","data-cy":"Submit preference"},on:{"click":function($event){_vm.step += _vm.preferenceErrors.length ? 0 : 1}}},[_vm._v("Proceed to next step")])],1):_vm._e()],2)],1),_c('v-window-item',[_c('BackButton',{attrs:{"text":"Release details"},on:{"click":function($event){_vm.step -= 1}}}),_c('div',{staticClass:"text-h2 mb-10 mt-2"},[_vm._v("Confirm application")]),_c('ApplicationDetails',{staticClass:"mt-6 mb-4 white",attrs:{"release":_vm.release,"sellerLogo":"","lots":""}}),_c('p',{staticClass:"text-h3 mt-12"},[_vm._v("Your Preferences")]),_c('LotPreferenceForm',{attrs:{"value":_vm.preferences,"lots":_vm.release.lots,"property-type":_vm.release.details.propertyType,"preferred-only":""}}),_c('p',{staticClass:"text-h3 mb-6 mt-12"},[_vm._v("Your details")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('ApplicantDetailsForm',{attrs:{"lots":_vm.release.lots},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),_c('AcceptTermsAndConditions'),_c('ConfirmationCheckbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I understand that once I confirm my preferences, I will not be able to edit them. Submitting multiple times (under my or other individuals names) may result in my preferences being null and void. ")])]},proxy:true}],null,true)}),_c('InfoCard',{staticClass:"mt-6 mb-12",attrs:{"flat":"","padding":4},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$info")])]},proxy:true}],null,true)},[_vm._v(" Applications close and must be submitted by "),_c('ApplicationCloseDate',{attrs:{"date":_vm.release.details.applicationsCloseDate}})],1),_c('ValidationMessages',{staticClass:"float-right",attrs:{"value":_vm.submitErrors}},[_c('v-btn',{attrs:{"data-cy":"confirm preference","disabled":invalid ||
                !Object.keys(_vm.preferences).length ||
                _vm.ReleaseStatus.Published !== _vm.release.status,"block":_vm.$vuetify.breakpoint.xs,"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Submit preferences")])],1)]}}])})],1)],1),(_vm.isOnDemandPurchase)?_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.isPurchasing),callback:function ($$v) {_vm.isPurchasing=$$v},expression:"isPurchasing"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"pa-1 text-h2"},[_vm._v(" Buy Now Application ")]),_c('v-card-text',{staticClass:"pa-2"},[_c('p',{staticClass:"text-h5 mb-6"},[_vm._v("Your details")]),_c('ApplicantDetailsForm',{model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),_c('AcceptTermsAndConditions'),_c('InfoCard',{staticClass:"mt-3 mb-3",attrs:{"flat":"","padding":4},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$info")])]},proxy:true}],null,true)},[_vm._v(" Once you submit this form, you will have "),_c('b',[_vm._v("15 minutes")]),_vm._v(" to complete the particulars and provide evidence of a paid deposit to secure the property. ")])],1),_c('v-card-actions',[_c('ValidationMessages',{attrs:{"value":_vm.submitErrors}},[_c('v-btn',{attrs:{"data-cy":"submit application","disabled":invalid,"block":_vm.$vuetify.breakpoint.xs,"color":"primary"},on:{"click":_vm.submitPurchase}},[_vm._v("Submit Application")])],1)],1)],1)]}}],null,false,2542699084)})],1):_vm._e(),(_vm.release.details.useDeveloperImageWeb)?_c('v-img',{staticClass:"developerImage",attrs:{"src":_vm.release.developerImage.url}}):_vm._e()],1):_c('ProgressCentered',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})}
var staticRenderFns = []

export { render, staticRenderFns }