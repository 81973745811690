



















































































import { Vue, Component } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ApplicantDetailsForm extends Vue {
  details = {
    givenName: '',
    familyName: '',
    emailAddress: '',
    confirmEmailAddress: '',
    phoneNumber: '',
  }

  emit() {
    this.$emit('input', this.details)
  }
}
