






















































































































































































































import ApplicationDetails from '@/components/ApplicationDetails.vue'
import ReleaseDocuments from '@/components/ReleaseDocuments.vue'
import ApplicationCloseDate from '@/components/ApplicationCloseDate.vue'
import BackButton from '@/components/BackButton.vue'
import LotPreferenceForm from '@/components/forms/LotPreferenceForm.vue'
import AcceptTermsAndConditions from '@/components/inputs/AcceptTermsAndConditions.vue'
import ApplicantDetailsForm from '@/components/forms/ApplicantDetailsForm.vue'
import ConfirmationCheckbox from '@/components/inputs/ConfirmationCheckbox.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import InfoCard from '@/components/InfoCard.vue'
import ProgressCentered from '@/components/ProgressCentered.vue'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { validate, ValidationObserver } from 'vee-validate'
import { LotDTO, ReleaseDTO } from '@/api-client'
import { AxiosError, AxiosResponse } from 'axios'
import { ordinal, listAsString } from '@/modules/stringUtils'
import ReleaseStatus, { NonPreviewStatuses } from '@/types/ReleaseStatus'
import { ApiErrorCode } from '@/types/ApiError'
import { CONTACT_EMAIL } from '@/modules/config'

@Component<Release>({
  components: {
    ValidationObserver,
    ProgressCentered,
    InfoCard,
    ValidationMessages,
    ApplicationCloseDate,
    ConfirmationCheckbox,
    ApplicantDetailsForm,
    AcceptTermsAndConditions,
    LotPreferenceForm,
    ApplicationDetails,
    BackButton,
    ReleaseDocuments,
  },
  head: {
    title() {
      return {
        inner: this.name,
      }
    },
  },
})
export default class Release extends Vue {
  ReleaseStatus = ReleaseStatus
  NonPreviewStatuses = NonPreviewStatuses
  CONTACT_EMAIL = CONTACT_EMAIL
  timerHandle: number | null = null

  loading = true
  release: ReleaseDTO | null = null
  preferences: { [id: string]: number } = {}
  step = 0
  details = {
    givenName: '',
    familyName: '',
    emailAddress: '',
    phoneNumber: '',
  }
  purchasingLot: LotDTO | null = null
  isPurchasing = false
  preferenceErrors: string[] = []
  submitErrors: string[] = []
  releaseError: {
    title: string
    illustration: string
    subtitle: string
  } | null = null

  get isOnDemandPurchase() {
    return this.release?.status == ReleaseStatus.OnDemandPurchasing
  }

  get lots() {
    return this.release?.lots
  }

  checkPreferences() {
    this.preferenceErrors = []
    const values = Object.values(this.preferences)
      .filter(v => (v as unknown) !== '')
      .sort((a, b) => a - b)

    if (values.some(v => v < 1))
      this.preferenceErrors.push(
        `You may not have a preference that is less than 1`,
      )
    if (values.some(v => v > this.maximumPreferences))
      this.preferenceErrors.push(
        `You cannot have a preference higher than ${this.maximumPreferences}`,
      )

    const duplicates = values.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && !acc.includes(el)) acc.push(el)
      return acc
    }, [] as number[])
    for (const dupe of duplicates) {
      if (dupe > 0)
        this.preferenceErrors.push(
          `You cannot have more than one ${ordinal(dupe)} preference`,
        )
    }

    // Check to see if there are any "gaps" in the preferences
    // i.e. 1, 2, 4, 5 is invalid
    let checkedPreferences = 1
    const missingPreferences = []
    for (let i = 0; i < values.length; i++) {
      if (values[i] > this.maximumPreferences) break
      while (values[i] > checkedPreferences) {
        missingPreferences.push(checkedPreferences)
        checkedPreferences++
      }
      checkedPreferences++
    }

    if (missingPreferences.length)
      this.preferenceErrors.push(
        `You are missing a ${listAsString(
          missingPreferences.map(ordinal),
        )} preference`,
      )

    if (!values.length) {
      this.preferenceErrors = ['You must select at least one preference']
      return
    }
  }

  async submit() {
    this.loading = true
    await this.$api.application
      .v1ApplicationsPost({
        ...this.details,
        releaseId: this.release!.id!,
        preferences: Object.entries(this.preferences)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, order]) => (order as unknown) !== '')
          .map(([lotId, order]) => ({
            lotId,
            order,
          })),
      })
      .then(res => {
        this.$router.push({
          path: `/applications/${res.data.id}`,
          query: {
            key: res.data.key,
          },
        })
      })
      .catch(() => {
        this.submitErrors = ['An error occurred']
      })
    this.loading = false
  }

  async submitPurchase() {
    if (!this.purchasingLot) return

    this.loading = true
    await this.$api.purchase
      .v1PurchasesPost({
        ...this.details,
        releaseId: this.release!.id!,
        preferences: [
          {
            lotId: this.purchasingLot!.id!,
            order: 1,
          },
        ],
      })
      .then(res => {
        this.$router.push({
          path: `/applications/${res.data.id}`,
          query: {
            key: res.data.key,
          },
        })
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) {
          this.submitErrors = [
            'This property is not currently available. This can be caused from another buyer starting this process or the seller removing the property from sale. Please try again later or get in contact with the sales person.',
          ]
        } else {
          this.submitErrors = ['An error occurred']
        }
      })
    this.loading = false
  }

  get name() {
    return this.release?.details?.name ?? `Release ${this.$route.params.code}`
  }

  get maximumPreferences() {
    return this.release ? Math.min(this.release.lots.length, 10) : 10
  }

  triggerPurchase(lot: LotDTO) {
    this.isPurchasing = true
    this.purchasingLot = lot
  }

  async beforeMount() {
    this.loading = true
    const codeValidation = await validate(
      this.$route.params.code,
      'releaseCode',
    )
    if (codeValidation.valid) {
      await this.loadReleaseDetails()
      this.startRefreshInterval()
    } else {
      this.releaseError = {
        title: 'Release not found.',
        subtitle:
          'Please make sure the correct URL is entered and try again. If problem persist, please contact us.',
        illustration: '/img/release-not-found.svg',
      }
    }
  }

  startRefreshInterval() {
    if (this.isOnDemandPurchase) {
      this.timerHandle = window.setInterval(this.loadReleaseDetails, 10000)
    }
  }

  beforeDestroy() {
    if (this.timerHandle) {
      window.clearInterval(this.timerHandle)
    }
  }

  loadReleaseDetails() {
    return this.$api.release
      .v1ReleasesGet(this.$route.params.code)
      .then(res => {
        if (res.status === 200) {
          this.release = ((res as unknown) as AxiosResponse<ReleaseDTO>).data
          this.loading = false
        } else {
          this.releaseError = this.releaseError = {
            title: 'Release not found',
            subtitle:
              'Please make sure the correct URL is entered and try again. If problem persist, please contact us.',
            illustration: '/img/release-not-found.svg',
          }
        }
      })
      .catch(err => {
        if (err.response?.data?.code === ApiErrorCode.RELEASE_COMPLETE) {
          this.releaseError = {
            title: 'Release concluded',
            subtitle: 'This release has concluded and is no longer available.',
            illustration: '/img/release-concluded.svg',
          }
        } else if (
          err.response?.data?.code === ApiErrorCode.RELEASE_CANCELLED
        ) {
          this.releaseError = {
            title: 'Release is no longer available.',
            subtitle: 'Unfortunately this release is no longer available.',
            illustration: '/img/release-cancelled.svg',
          }
        } else {
          // One of
          // RELEASE_NOT_PUBLISHED
          // RELEASE_NOT_FOUND
          this.releaseError = {
            title: 'Release not found',
            subtitle:
              'Please make sure the correct URL is entered and try again. If problem persist, please contact us.',
            illustration: '/img/release-not-found.svg',
          }
        }
      })
  }

  @Watch('step')
  scrollToTop() {
    window.scroll(0, 0)
  }
}
