var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required","name":"Given name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Given name","autocomplete":"given-name","outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.details.givenName),callback:function ($$v) {_vm.$set(_vm.details, "givenName", $$v)},expression:"details.givenName"}})]}}])}),_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required","name":"Family name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Family name","autocomplete":"family-name","outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.details.familyName),callback:function ($$v) {_vm.$set(_vm.details, "familyName", $$v)},expression:"details.familyName"}})]}}])}),_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|email","name":"Email address","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"email","placeholder":"Email","type":"email","outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.details.emailAddress),callback:function ($$v) {_vm.$set(_vm.details, "emailAddress", $$v)},expression:"details.emailAddress"}})]}}])}),_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|confirmed:email","name":"Confirm email address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Confirm email","type":"email","outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.details.confirmEmailAddress),callback:function ($$v) {_vm.$set(_vm.details, "confirmEmailAddress", $$v)},expression:"details.confirmEmailAddress"}})]}}])}),_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|mobile","name":"Mobile number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"tel","placeholder":"Mobile number","type":"tel","outlined":"","error-messages":errors},on:{"change":_vm.emit},model:{value:(_vm.details.phoneNumber),callback:function ($$v) {_vm.$set(_vm.details, "phoneNumber", $$v)},expression:"details.phoneNumber"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }